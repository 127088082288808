<template>
  <BM></BM>
</template>

<script>
import BM from "../../components/product/BM";
export default {
  components: { BM },
};
</script>

<style></style>
