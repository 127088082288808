<template>
  <EOS></EOS>
</template>

<script>
import EOS from "../../components/product/EOS";
export default {
  components: {
    EOS,
  },
};
</script>

<style></style>
