<template>
  <EFS></EFS>
</template>

<script>
import EFS from "../../components/product/EFS";
export default {
  components: { EFS },
};
</script>

<style></style>
