<template>
  <EVM></EVM>
</template>

<script>
import EVM from "../../components/product/EVM";
export default {
  components: { EVM },
};
</script>

<style></style>
