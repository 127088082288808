<template>
  <VPN></VPN>
</template>

<script>
import VPN from "../../components/product/VPN";
export default {
  components: { VPN },
};
</script>

<style></style>
