<template>
  <EDM></EDM>
</template>

<script>
import EDM from "../../components/product/EDM";
export default {
  components: { EDM },
};
</script>

<style></style>
