<template>
  <Web></Web>
</template>

<script>
import Web from "../../components/product/Web";
export default {
  components: { Web },
};
</script>

<style></style>
